import React, { useRef, useState } from "react";
import { Typography, Card, Link } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import imageNotAvailable from "../../../../assests/images/img-soon.png";
import {
  Stack,
  Grid,
} from "@mui/material";
import phone from '../../../../assests/images/call-icon.svg';
import Imessage from '../../../../assests/images/imessage-icon.svg';
import whatsappIcon from '../../../../assests/images/whatsapp-icon.svg';
import FacebookIcon from '../../../../assests/images/facebook-icon.svg';
import Telegram from '../../../../assests/images/telegram-icon.svg';
import LaunchIcon from '@material-ui/icons/Launch';
import { isMobile } from "react-device-detect";
import { useHistory } from 'react-router-dom';
import constants from "../../../../utils/constants";
import html2canvas from 'html2canvas';
import { toast } from "react-toastify";
import { communicationActivityLog } from "../actions/dealerInventoryaction";
import { formatDate } from "../../../../common/components/Helper/Utils";
import VideoPlayer from "simple-react-video-thumbnail";

const useStyles = makeStyles({
  icon: {
    fontSize: '19px !important',
  },
});

const InventoryStatusLookup = {
  1: "Inhand",
  2: "Incoming",
  3: "Out on Memo",
  4: "On Hold / Reserved",
  5: "Available to Order",
  6: "Needs Service / Polish",
  7: "At Service",
  8: "Need to Ship",
  9: "Shipped waiting on payment",
  10: "Sold & Cashed Out",
  11: "Intake Complete",
};

const DealerDetailedCardView = ({ item, handleInventoryView }) => {
  const classes = useStyles();
  const history = useHistory();
  const userData = sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null;
  const [loader, setloader] = useState(false);
  const communicationLogo = {
    // phone: phone,
    // imessage: Imessage,
    whatsapp: whatsappIcon,
    // facebook: FacebookIcon,
    // telegram: Telegram
  }
  const captureRef = useRef();

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const getImageBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'image/png', // Ensure the correct Content-Type is set
          'Cache-Control': 'no-cache', // Example of setting a Cache-Control header
          'Access-Control-Allow-Origin': '*', // Example of an additional CORS header
        },
        mode: 'cors', // Ensure CORS mode is enabled
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      const blob = await response.blob();
      const base64 = await toBase64(blob);
      return base64;
    } catch (error) {
      console.error('Error converting image to Base64:', error);
    }
  };

  const handleCaptureAndCopy = async () => {
    setloader(true);
    await getImageBase64(captureRef.current.src).then(base64 => {
      captureRef.current.src = base64
    });
    if (captureRef.current) {
      try {
        // Ensure the element is fully rendered
        await new Promise((resolve) => setTimeout(resolve, 200));
        // Capture the DOM element as a canvas with additional options
        const canvas = await html2canvas(captureRef.current, {
          useCORS: true,
          scale: window.devicePixelRatio,
          logging: true,
        });
        // Convert the canvas to a Blob
        canvas.toBlob(async (blob) => {
          if (blob) {
            try {
              if (!document.hasFocus()) {
                window.focus();
              }
              if (typeof ClipboardItem === 'undefined') {
                window.ClipboardItem = function (items) {
                  return items;
                };
              }
              if (!navigator.clipboard) {
                console.log('Clipboard API not available');
                throw new Error('Clipboard API not available');
              }
              // Write the Blob to the clipboard
              await navigator.clipboard.write([
                new window.ClipboardItem({ 'image/png': blob })
              ]);
            } catch (error) {
              console.error('Failed to copy:', error);
            }
          }
        });
      } catch (error) {
        console.error('Failed to capture:', error);
      }
    }
    setloader(false);
  };

  const handleActivityLogCommunication = (item, type) => {
    const { dealerfeeddetails, inventoryid } = item ?? {};
    const { dealer } = dealerfeeddetails ?? {};
    const { accountid = null } = dealer?.[0] ?? [];
    let payload = {
      accountid: userData?.accountId ?? null,
      userid: userData?.userId ?? null,
      inventoryid: inventoryid ?? null,
      communication_type: type,
      from_source: "Portal",
      dealercontacted_accountid: accountid,
    }
    communicationActivityLog(payload, (err, response) => {
      if (response?.data) {
        // Do something
      }
      if (err) {
        console.log("ERROR", err);
      }
    });
  };

  const redirectSocialMedia = async (item, data) => {
    const {
      type = "",
      brandname = "",
      prodattr,
      technical_details,
      condition = "",
      inventoryid = "",
      total_cost = 0,
      category: category_value = "",
      countrycode = constants.COUNTRY_CODE
    } = data ?? {};
    const { dialcolor } = prodattr ?? {};
    const { product_title = "", category = "" } = technical_details ?? {};

    const standardWatchDetails =
      `Inventory ID: ${inventoryid}
Brand: ${brandname}
Dial Color: ${dialcolor}
Condition: ${condition === 4 ? 'New' : 'Pre-owned'}`;

    const miscProductDetails =
      `Inventory ID: ${inventoryid}
Product Name: ${product_title}
Category: ${category ?? category_value}
Condition: ${condition === 4 ? 'New' : 'Pre-owned'}`;

    const phoneNumber = item.value;
    // const imageUrl = data?.imagepaths ?
    //   (data?.imagepaths?.front || data?.imagepaths?.back || data?.imagepaths?.left || data?.imagepaths?.right || data?.imagepaths?.free_form || data?.imagepaths?.buckle_opened || data?.imagepaths?.buckle_closed || data?.imagepaths?.wrist_shot)
    //   : (data?.product_imagepaths ? data?.product_imagepaths?.images?.[0] : null);
    let baseURL = constants.SHARE_INVENTORY;
    let url = `${baseURL}dealerinventory/inventory-view/${inventoryid}`
    const name = data?.businessname;
    let message;
    if (type === "misc_product") {
      message = `${url ? `${url}\n\n` : ''}Hi ${name},\n\nI am interested in purchasing the item below, so please provide me with more details.\n\n${miscProductDetails}`
    } else {
      message = `${url ? `${url}\n\n` : ''}Hi ${name},\n\nI am interested in purchasing the item below, so please provide me with more details.\n\n${standardWatchDetails}`
    }

    if (item.mode == 'whatsapp') {
      handleActivityLogCommunication(data, "Whatsapp Web");
      if (isMobile) {
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
      }
      else {
        await handleCaptureAndCopy();
        toast("Image copied to clipboard. Paste it in WhatsApp chat if needed.", {
          autoClose: 3000, // 3 seconds
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER,
        });

        setTimeout(() => {
          const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
          window.open(whatsappWebUrl, '_blank');
        }, 2000); // Delay of 2 seconds
      }
    }
    else if (item.mode == 'telegram') {
      handleActivityLogCommunication(data, "Telegram Web");
      if (isMobile) {
        const telegramUrl = `tg://msg?to=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(telegramUrl, '_blank');
      }
      else {
        const telegramUrl = `https://t.me/${countrycode}${phoneNumber}?text=${message.replace(/\n/g, '%0A')}`;
        window.open(telegramUrl, '_blank');
      }
    }
  }

  const imageDestructure = (path) => {
    // if (path.type === 'misc_watch' || path.type === 'misc_product') {
    //   const image = path.imagepaths ? path.imagepaths : null
    //   return image ? (image.front || image.back || image.left || image.right || image.free_form || image.buckle_opened || image.buckle_closed || image.wrist_shot) : null;
    // }
    // else if (path.type === 'standard_watch' || path.type == null) {
    const { front, back, left, right, free_form, buckle_opened, buckle_closed, wrist_shot } = path?.imagepaths ?? {};
    const image = front ?? back ?? left ?? right ?? free_form ?? buckle_opened ?? buckle_closed ?? wrist_shot;
    return image;
    // }
  }

  const handleNextPage = (data) => {
    // history.push(`/dealerfeed/${data?.businessname ? data?.businessname?.replace(/\s/g, '') : ""}`);
    const nextPageUrl = `/dealerfeed/${data?.businessname ? data?.businessname?.replace(/[\s.]/g, '') : ""}`;
    window.open(nextPageUrl, '_blank');
  }

  const {
    type,
    brandname,
    prodattr,
    technical_details,
    condition,
    dealerfeeddetails,
    targetwholesaleprice,
    targetendcustomerprice,
    public_dealer_note,
    inventory_status,
    inventory_title,
    inventory_date,
    inventoryid,
    modification_deatils,
    warrantybox,
    warrantypaper,
    warrantydate,
    product_imagepaths,
    businessname,
    total_cost,
    createdon,
    category: category_value
  } = item ?? {};
  const { images } = product_imagepaths ?? {};
  const { reference, dialcolor } = prodattr ?? {};
  const { brand_name, product_title, category, dial_color } = technical_details ?? {};
  const { dealer } = dealerfeeddetails ?? {};
  const {
    wholesale_price,
    inventory_status: inventory_status_enabled,
    endcustomer_price,
    isdealerfeed_enabled,
  } = dealer?.[0] ?? [];

  const imageSrc = imageDestructure(item);
  const videoSource = item?.imagepaths?.video;
  return (
    <>
      <Grid item xs={12} md={6} key={item?.inventoryid} className="dealer-container">
        <Card Card className="dealerbox">
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "0px",
                justifyContent: "center"
              }}
              mb={3}
            >
              <div
                className="text-center1"
                style={{ paddingLeft: "30px" }}
              >
                {imageSrc ?
                  <img
                    src={imageSrc}
                    alt="rolex"
                    className="img-responsive1 inventry-img" ref={captureRef}
                    onClick={() => handleInventoryView(item)}
                    style={{ cursor: "pointer" }}
                  /> : videoSource ?
                    <VideoPlayer
                      videoUrl={videoSource + "?autoplay=1"}
                      snapshotAt={10}
                    /> :
                    <img
                      src={item?.product_imagepaths?.images?.[0] ?? imageNotAvailable}
                      alt="rolex"
                      className="img-responsive1 inventry-img" ref={captureRef}
                      onClick={() => handleInventoryView(item)}
                      style={{ cursor: "pointer" }}
                    />
                }
                {item.dealerfeeddetails?.dealer && item.dealerfeeddetails?.dealer[0]?.communication?.communication?.length &&
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    {item.dealerfeeddetails?.dealer[0]?.communication?.communication.map(communicationChannel => {
                      if (communicationChannel?.mode !== "facebook") {
                        return (
                          <img
                            style={{ cursor: loader ? "wait" : "pointer" }}
                            src={communicationLogo[communicationChannel.mode]}
                            onClick={() => redirectSocialMedia(communicationChannel, item)} />
                        )
                      }
                    })}
                  </div>
                }
              </div>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                paddingTop: "0px",
                justifyContent: { xs: "center", md: 'flex-start' },
              }}
            >
              <Grid container spacing={0.5} display="flex" alignItems="center">
                {/* <Grid item md={5}>
                <Typography variant="body2"> <b>Dealer Name </b> </Typography>
              </Grid>
              <Grid item md={0.5}><span>: </span></Grid> */}
                <Grid item xs={12} md={12} mb={1}>
                  <Link component="button" onClick={() => handleNextPage(item)} >
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "16px", color: "blue", textDecoration: "underline",
                        display: "flex", alignItems: "center"
                      }}
                    >
                      <b style={{ paddingRight: "5px" }}>{businessname ?? "-"}</b>
                      <LaunchIcon className={classes.icon} color="primary" />
                    </Typography>
                  </Link>
                </Grid>

                {inventory_title &&
                  <Grid item xs={12} md={12} mb={0.5}>
                    <Typography
                      variant="subtitle2"
                      className="textSecondary color-292C2 MuiTypography-textupper"
                      component="p"
                    >
                      <b>{inventory_title ?? "-"} </b>
                    </Typography>
                  </Grid>}

                <Grid item xs={5} md={5}>
                  <Typography variant="body2"> Brand </Typography>
                </Grid>
                <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                <Grid item xs={6.5} md={6.5}>
                  <Typography variant="body2">{brandname ?? brand_name ?? "-"} </Typography>
                </Grid>

                {/* <Grid item md={5}>
                <Typography variant="body2"> Ref # </Typography>
              </Grid>
              <Grid item md={0.5}><span>: </span></Grid>
              <Grid item md={6.5}>
                <Typography variant="body2">{reference ?? "-"} </Typography>
              </Grid> */}

                {type !== "misc_product" && (
                  <>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body2"> Dial Color </Typography>
                    </Grid>
                    <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                    <Grid item xs={6.5} md={6.5}>
                      <Typography variant="body2">{dialcolor ?? dial_color ?? "---"} </Typography>
                    </Grid>

                    <Grid item xs={5} md={5}>
                      <Typography variant="body2"> Box </Typography>
                    </Grid>
                    <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                    <Grid item xs={6.5} md={6.5}>
                      <Typography variant="body2">{warrantybox === "1" ? "Yes" : "No"} </Typography>
                    </Grid>

                    <Grid item xs={5} md={5}>
                      <Typography variant="body2"> Warranty </Typography>
                    </Grid>
                    <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                    <Grid item xs={6.5} md={6.5}>
                      <Typography variant="body2">{warrantypaper ? "Yes" : "No"} </Typography>
                    </Grid>

                    <Grid item xs={5} md={5}>
                      <Typography variant="body2"> Warranty Year </Typography>
                    </Grid>
                    <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                    <Grid item xs={6.5} md={6.5}>
                      {/* <Typography variant="body2">{warrantydate ? moment(warrantydate).format("YYYY") : "-"} </Typography> */}
                      <Typography variant="body2">{warrantydate ? formatDate(warrantydate, "YYYY") : "-"} </Typography>
                    </Grid>
                  </>
                )}

                {type === "misc_product" && (
                  <>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body2"> Product Name </Typography>
                    </Grid>
                    <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                    <Grid item xs={6.5} md={6.5}>
                      <Typography variant="body2">{product_title ?? "---"} </Typography>
                    </Grid>

                    <Grid item xs={5} md={5}>
                      <Typography variant="body2"> Category </Typography>
                    </Grid>
                    <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                    <Grid item xs={6.5} md={6.5}>
                      <Typography variant="body2">{category ?? category_value ?? "---"} </Typography>
                    </Grid>
                  </>
                )}

                <Grid item xs={5} md={5}>
                  <Typography variant="body2"> Condition </Typography>
                </Grid>
                <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                <Grid item xs={6.5} md={6.5}>
                  <Typography variant="body2">
                    {condition === 4 ? 'New' : 'Pre-owned'}
                  </Typography>
                </Grid>

                {inventory_status_enabled && (
                  <>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body2"> Inventory Status </Typography>
                    </Grid>
                    <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                    <Grid item xs={6.5} md={6.5}>
                      <Typography variant="body2">{InventoryStatusLookup[inventory_status] ?? "-"} </Typography>
                    </Grid>
                  </>
                )}

                {wholesale_price && (
                  <>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body2"> Target Wholesale Price </Typography>
                    </Grid>
                    <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                    <Grid item xs={6.5} md={6.5}>
                      <Typography variant="body2">{`$ ${targetwholesaleprice ?? "---"}`} </Typography>
                    </Grid>
                  </>
                )}

                {endcustomer_price && (
                  <>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body2"> Target End Customer Price </Typography>
                    </Grid>
                    <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                    <Grid item xs={6.5} md={6.5}>
                      <Typography variant="body2">{`$ ${targetendcustomerprice ?? "---"}`} </Typography>
                    </Grid>
                  </>
                )}

                {/* {isdealerfeed_enabled && (
                <>
                  <Grid item xs={5} md={5}>
                    <Typography variant="body2"> Public Dealer Notes </Typography>
                  </Grid>
                  <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                  <Grid item xs={6.5} md={6.5}>
                    <Typography variant="body2">{public_dealer_note ?? "-"} </Typography>
                  </Grid>
                </>
              )} */}

                {/* <Grid item xs={5} md={5}>
                <Typography variant="body2"> Inventory ID </Typography>
              </Grid>
              <Grid item md={0 xs={0.5}.5}><span>: </span></Grid>
              <Grid item mdxs={6.5} ={6.5}>
                <Typography variant="body2">{inventoryid ?? "-"} </Typography>
              </Grid>

              <Grid item xs={5} md={5}>
                <Typography variant="body2"> Modified Details </Typography>
              </Grid>
              <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
              <Grid item xs={6.5} md={6.5}>
                <Typography variant="body2">{modification_deatils ?? "-"} </Typography>
              </Grid> */}

                {/* <Grid item xs={5} md={5}>
                  <Typography variant="body2"> Total Cost </Typography>
                </Grid>
                <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                <Grid item xs={6.5} md={6.5}>
                  <Typography variant="body2">
                    {total_cost ? `$ ${total_cost}` : "-"}
                    {total_cost && !isNaN(total_cost) ? `$${parseFloat(total_cost).toLocaleString('en-US')}` : "-"}
                  </Typography>
                </Grid> */}
                {/* <Grid item xs={5} md={5}>
                  <Typography variant="body2"> Inventory Date </Typography>
                </Grid>
                <Grid item xs={0.5} md={0.5}><span>: </span></Grid>
                <Grid item xs={6.5} md={6.5}>
                  <Typography variant="body2">
                    {createdon ? moment(createdon).format('MM/DD/YYYY') : "-"}
                    //{total_cost && !isNaN(total_cost) ? `$${parseFloat(total_cost).toLocaleString('en-US')}` : "-"} 
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Card >
      </Grid >
    </>
  );
};

export default DealerDetailedCardView;