import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Container,
    Grid,
    Button,
    InputLabel,
    FormControl,
    Input,
    Typography,
    Box,
    Select,
    MenuItem,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import imageNotAvailable from "../../../../assests/images/img-soon.png";
import { useHistory, useLocation } from "react-router-dom";
import {
    Popover as AntdPopover,
    Input as AntdInput,
    Modal as AntdModal,
} from "antd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import ContentWrapper from "../../../../common/components/contentWrapper";
import ReasonDialog from "../../../../common/components/ReasonDialog";
import { getMemoList, UpdateMemoDetails } from "../actions/dashboardAction";
import {
    showErrorToast,
    showSuccessToast,
} from "../../../../common/components/Helper/Utils";
import { SpinnerRoundFilled } from "spinners-react";

const invoiceObj = [
    {
        id: "modelnumber",
        label: "Model",
        minWidth: 100,
        type: "search",
        required: false,
        errMsg: "Please Enter Model",
    },
    { id: "image", label: "Image", minWidth: 80, type: "image", required: false },
    {
        id: "serialno",
        label: "Serial No",
        minWidth: 80,
        type: "search",
        required: false,
        errMsg: "Please Enter Serial No",
    },
    {
        id: "description",
        label: "Product Name",
        minWidth: 140,
        type: "text",
        required: false,
    },
    {
        id: "quantity",
        label: "Qty",
        minWidth: 80,
        type: "number",
        required: true,
        errMsg: "Please enter quantity",
    },
    {
        id: "currency",
        label: "Currency",
        minWidth: 30,
        type: "select",
        required: true,
        errMsg: "Please enter rate",
    },
    {
        id: "amount",
        label: "Amount",
        minWidth: 65,
        type: "amount",
        required: true,
        errMsg: "Please Enter Amount",
    },
    {
        id: "status",
        label: "Status",
        minWidth: 65,
        type: "text",
        required: false,
    },
    { id: "action", label: "Action", minWidth: 70, align: "center" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function UpdateMemoInvoice(props) {
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const history = useHistory();
    const location = useLocation();
    const { row, invoiceFilter } = location.state || {};
    const [data, setData] = useState({});
    const [openModal, setModalOpen] = useState(false);
    const [returnModal, setReturnModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [modelnumber, setModelNumber] = useState(null);
    const [serialno, setSerialNo] = useState(null);
    const [currency, setCurrency] = useState("USD");
    const [amount, setAmount] = useState(null);
    const [desc, setDesc] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [reason, setReason] = useState(null);
    const [noData, setNoData] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [reasonError, setReasonError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // let item = row || {};
        // setData(item);
        getMemoDetails(row?.id);
    }, [row]);

    const getMemoDetails = (memoId) => {
        setIsLoading(true);
        props.getMemoList(
            memoId,
            (success) => {
                if (success.status === 1) {
                    setData(success?.data?.[0]);
                    setIsLoading(false);
                } else {
                    showErrorToast(success.message);
                    setIsLoading(false);
                }
            },
            (error) => {
                showErrorToast(error);
                setIsLoading(false);
            }
        );
    };

    const handleReasonChange = (e) => {
        const { value } = e.target;
        setReason(value);
        setReasonError(!value);
    };

    const onSubmitReturnMemo = () => {
        if (!reason) {
            setReasonError(true);
            return;
        }
        setDisabled(true);
        let data = {
            memodetailId: currentItem.memoprimaryid || null,
            memoId: currentItem.memoid || null,
            returnNotes: reason || null,
            inventoryId: currentItem.inventoryid || null,
        };
        props.UpdateMemoDetails(
            data,
            (success) => {
                if (success.status === 1) {
                    setDisabled(false);
                    handleCloseModal();
                    showSuccessToast(success.message);
                    getMemoDetails(row?.id);
                } else {
                    setDisabled(false);
                    showErrorToast(success.message);
                }
            },
            (error) => {
                setDisabled(false);
                showErrorToast(error);
            }
        );
    };

    // const updateProductStatus = (memodetailid, newStatus) => {
    //     setData((prevData) => {
    //         const updatedData = { ...prevData };
    //         updatedData.invoiceproductdetails.product =
    //             updatedData.invoiceproductdetails.product.map((product) =>
    //                 product.memodetailid === memodetailid
    //                     ? { ...product, status: newStatus }
    //                     : product
    //             );
    //         return updatedData;
    //     });

    //     //For updating location state
    //     const updatedProduct =
    //         location.state.row?.invoiceproductdetails?.product?.map((item) =>
    //             item.memodetailid == memodetailid
    //                 ? { ...item, status: newStatus }
    //                 : item
    //         );
    //     const updatedRow = {
    //         ...location.state.row,
    //         invoiceproductdetails: {
    //             ...location.state.row.invoiceproductdetails,
    //             product: updatedProduct,
    //         },
    //     };
    //     const updatedState = {
    //         ...location.state,
    //         row: updatedRow,
    //     };
    //     history.replace({ ...location, state: updatedState });
    // };

    // const handleModalOpen = (item) => {
    //     setCurrentItem(item);
    //     setModalOpen(true);
    //     setModelNumber(item.modelnumber);
    //     setSerialNo(item.serialno);
    //     setDesc(item.description);
    //     setQuantity(item.quantity);
    //     setAmount(item.amount);
    // };

    const handleCloseModal = () => {
        setModalOpen(false);
        setReturnModal(false);
        setCurrentItem(null);
        setModelNumber(null);
        setSerialNo(null);
        setDesc(null);
        setQuantity(null);
        setAmount(null);
        setReason(null);
        setReasonError(null);
    };

    const handleGoBack = (invoiceFilter) => {
        history.push({
            pathname: "/invoices",
            state: { invoiceFilter }
        });
    }

    const getContentView = () => {
        return (
            <Container maxWidth="md">
                {isLoading ?
                    <div class="d-spinner">
                        <SpinnerRoundFilled
                            size={51}
                            thickness={125}
                            speed={80}
                            color="rgba(57, 107, 172, 1)"
                            className="spp"
                            enabled={isLoading}
                        />
                    </div> :
                    <form key={1}>
                        <Grid container xs={12} sm={12}>
                            <h1 style={{ display: "flex", alignItems: "center" }}>
                                <ArrowBackIcon
                                    style={{
                                        color: "action",
                                        paddingRight: "15px",
                                        fontSize: 40,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => { handleGoBack(invoiceFilter) }}
                                >
                                    {" "}
                                </ArrowBackIcon>
                                Update Memo
                            </h1>
                        </Grid>
                        <TableContainer
                            sx={{ maxHeight: 440, marginTop: "20px", marginBottom: "20px" }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {invoiceObj
                                            .filter(({ id }) => id !== "currency")
                                            .map(({ id, align, minWidth, label }) => (
                                                <StyledTableCell
                                                    key={id}
                                                    align={align}
                                                    style={{ minWidth }}
                                                >
                                                    {label}
                                                </StyledTableCell>
                                            ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* {!data?.memoproductdetails?.memo?.some((item) => item.status !== "Returned" && item.status !== "Sold") ? ( */}
                                    {!data?.memoproductdetails?.memo ||
                                        data?.memoproductdetails?.memo?.length === 0 ? (
                                        <StyledTableRow>
                                            <TableCell colSpan={11} style={{ textAlign: "center" }}>
                                                No record found
                                            </TableCell>
                                        </StyledTableRow>
                                    ) : (
                                        data?.memoproductdetails?.memo
                                            // ?.filter((item) => item.status !== "Returned" && item.status !== "Sold")
                                            ?.map((row) => {
                                                const {
                                                    memoprimaryid,
                                                    status,
                                                    serialno,
                                                    product_imagepaths,
                                                    technical_details,
                                                    miscimages,
                                                    currency
                                                } = row;
                                                const {
                                                    front,
                                                    back,
                                                    left,
                                                    right,
                                                    free_form,
                                                    buckle_opened,
                                                    buckle_closed,
                                                    wrist_shot,
                                                } = miscimages ?? {};
                                                const { product_title, model_number } = technical_details || {};
                                                return (
                                                    <StyledTableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={memoprimaryid}
                                                    >
                                                        {invoiceObj
                                                            .filter(({ id }) => id !== "currency")
                                                            .map(({ id, align, type }) => {
                                                                if (id === "action") {
                                                                    return (
                                                                        <TableCell
                                                                            key={id}
                                                                            align={align}
                                                                            style={{ padding: 10 }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    gap: "10px",
                                                                                }}
                                                                            >
                                                                                {/* <IconButton
                                                                            onClick={() => handleModalOpen(row)}
                                                                            disabled={status === "Returned"}
                                                                            style={{ width: "19px" }}
                                                                        >
                                                                            <EditIcon />
                                                                        </IconButton> */}

                                                                                <Tooltip title="Return">
                                                                                    <IconButton
                                                                                        onClick={() => {
                                                                                            setReturnModal(true);
                                                                                            setCurrentItem(row);
                                                                                        }}
                                                                                        disabled={status?.toLowerCase() !== "out on memo"}
                                                                                        style={{ width: "21px" }}
                                                                                    >
                                                                                        <ChangeCircleOutlinedIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                return (
                                                                    <TableCell key={id} align={align}>
                                                                        {type === "image" ? (
                                                                            <img
                                                                                src={
                                                                                    product_imagepaths?.images?.[0] ||
                                                                                    front ||
                                                                                    back ||
                                                                                    left ||
                                                                                    right ||
                                                                                    free_form ||
                                                                                    buckle_opened ||
                                                                                    buckle_closed ||
                                                                                    wrist_shot ||
                                                                                    imageNotAvailable
                                                                                }
                                                                                width="35"
                                                                                height="55"
                                                                                alt="Image"
                                                                            />
                                                                        ) : id === "rate" || id === "amount" ? (
                                                                            // `${row[id]}`
                                                                            `${currency || null}${row[id]}`
                                                                        ) : id === "description" ? (
                                                                            (row[id] || product_title)
                                                                                ?.length > 50 ? (
                                                                                `${row[id]?.slice(0, 50)}...`
                                                                            ) : (
                                                                                row[id] || product_title
                                                                            )
                                                                        ) : id === "modelnumber" ? (
                                                                            row[id] || model_number
                                                                        ) : (
                                                                            row[id]
                                                                        )}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                    </StyledTableRow>
                                                );
                                            })
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <Grid
                        container
                        item
                        xs={12}
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "15px",
                        }}
                        className="space-marign-bottom-10"
                    >
                        <Button
                            variant="contained"
                            id="demo-customized-button"
                            disableElevation
                            onClick={() => history.push("/invoices")}
                            className="text-capitalize primary-bg color-white"
                        >
                            CANCEL
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            id="demo-customized-button"
                            disableElevation
                            onClick={() => { }}
                            className="text-capitalize primary-bg color-white"
                        >
                            UPDATE
                        </Button>
                    </Grid> */}
                    </form>
                }
                <AntdModal open={openModal} footer={null} onCancel={handleCloseModal}>
                    <Box>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Edit Item
                        </Typography>
                        <form key={3} onSubmit={handleSubmit()}>
                            {invoiceObj.map((column) => {
                                return (
                                    <>
                                        {column.id !== "action" &&
                                            column.id !== "status" &&
                                            (column.type === "text" || column.type === "number") && (
                                                <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                                                    <InputLabel
                                                        htmlFor="component-simple"
                                                        className="color-292C2F"
                                                    >
                                                        {column.label}
                                                    </InputLabel>
                                                    <Input
                                                        id={column.id}
                                                        value={
                                                            column.id === "description"
                                                                ? desc
                                                                : column.id === "quantity"
                                                                    ? quantity
                                                                    : 1
                                                        }
                                                        {...register(column.id, {
                                                            required: column.required ? column.errMsg : false,
                                                        })}
                                                        inputProps={{
                                                            type: column.type,
                                                            autoComplete: "off",
                                                        }}
                                                        readOnly={true}
                                                        disableUnderline={false}
                                                        disabled={true}
                                                    />
                                                    {errors[column.id] && (
                                                        <span style={{ color: "red" }}>
                                                            {column.errMsg}
                                                        </span>
                                                    )}
                                                </FormControl>
                                            )}
                                        {(column.id === "modelnumber" ||
                                            column.id === "serialno") && (
                                                <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                                                    <InputLabel
                                                        htmlFor="component-simple"
                                                        className="color-292C2F"
                                                    >
                                                        {column.label}
                                                    </InputLabel>
                                                    <Input
                                                        id={column.id}
                                                        value={
                                                            column.id === "modelnumber"
                                                                ? modelnumber
                                                                : column.id === "serialno"
                                                                    ? serialno
                                                                    : null
                                                        }
                                                        {...register(column.id, {
                                                            required: column.required ? column.errMsg : false,
                                                        })}
                                                        inputProps={{
                                                            type: column.type,
                                                            autoComplete: "off",
                                                        }}
                                                        readOnly={true}
                                                        disableUnderline={false}
                                                        disabled={true}
                                                    />
                                                    {errors[column.id] && (
                                                        <span style={{ color: "red" }}>{column.errMsg}</span>
                                                    )}
                                                </FormControl>
                                            )}
                                        {(column.type === "select" || column.type === "amount") && (
                                            <FormControl
                                                className={`form-width-${column.minWidth} margin-t-b-20 margin-p-b-20`}
                                                style={{
                                                    marginRight: column.type === "select" ? "16px" : 0,
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="component-simple"
                                                    className="color-292C2F"
                                                >
                                                    {column.label}
                                                </InputLabel>
                                                {column.type === "select" ? (
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={currency}
                                                        onChange={(e) => {
                                                            setCurrency(e.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value="USD">USD</MenuItem>
                                                        <MenuItem value="HK$">HK$</MenuItem>
                                                        <MenuItem value="Euro">Euro</MenuItem>
                                                        <MenuItem value="AED">AED</MenuItem>
                                                    </Select>
                                                ) : (
                                                    <Input
                                                        id="component-simple"
                                                        {...register(column.id, { required: true })}
                                                        inputProps={{
                                                            type: "text",
                                                            maxLength: "15",
                                                            autoComplete: "off",
                                                        }}
                                                        disableUnderline={false}
                                                        value={amount}
                                                        onChange={(e) => {
                                                            const val = e.target.value.replace(
                                                                /[^0-9\.]/g,
                                                                ""
                                                            );
                                                            setAmount(val);
                                                        }}
                                                    />
                                                )}
                                                {errors[column.id] && (
                                                    <span style={{ color: "red" }}>{column.errMsg}</span>
                                                )}
                                            </FormControl>
                                        )}
                                    </>
                                );
                            })}
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: "15px",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={handleCloseModal}
                                    style={{
                                        background: "#fff",
                                        color: "#000",
                                        marginTop: "20px",
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="text"
                                    style={{
                                        background: !noData ? "#000" : "#cccc",
                                        color: "#fff",
                                        marginTop: "20px",
                                    }}
                                    disabled={noData}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </AntdModal>

                {returnModal && (
                    <ReasonDialog
                        title="Return an Item"
                        message="Are you sure you want to return this item?"
                        label="Please enter a reason for return this item"
                        info='Returning this item will remove it from the memo invoice and transfer it back to inventory with a status of "In Hand"'
                        open={returnModal}
                        handleChange={handleReasonChange}
                        handleSubmit={onSubmitReturnMemo}
                        handleClose={handleCloseModal}
                        disabled={disabled}
                        error={reasonError}
                    />
                )}
            </Container>
        );
    };
    return <ContentWrapper contentView={getContentView()} />;
}

function mapStateToProps({ }) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        UpdateMemoDetails,
        getMemoList
    }, dispatch);
}

UpdateMemoInvoice.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMemoInvoice);
