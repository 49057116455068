import React from "react";
import Typography from "@mui/material/Typography";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { downloadInvoicepreview } from "./../../dealer/actions/dealerAction";
import { useEffect, useState } from "react";
import { SpinnerRoundFilled } from "spinners-react";

export default function ViewInvoicemodel(props) {
  const { open, handleClose, invoiceid } = props;
  const [pdfData, setPdfData] = useState("");
  const [Error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [invoiceID, setInvoiceId] = useState(null);

  const viewInvoice = () => {
    const { invoicetype, id, memonumber, memoid } = invoiceid;
    let isMemo = (invoicetype === "Memo Invoice" || memoid) ? true : false;
    setInvoiceId(isMemo ? (memonumber || memoid) : id);
    setLoading(true);
    downloadInvoicepreview(isMemo ? memoid : id, isMemo).then((res) => {
      setLoading(false);
      if (res && res.status === 0) {
        setError(
          "Sorry! Invoice preview not available. Please contact support"
        );
      } else if (res && res.url) {
        setPdfData(res.url);
        setError("");
      }
    });
  };

  useEffect(() => {
    viewInvoice();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        maxWidth={"lg"}
        PaperProps={{
          lg: { height: "96%" },
          sx: { height: "96%" },
          md: { height: "96%" },
        }}
      >
        <DialogTitle>
          Invoice Preview - {(invoiceid?.invoicetype === 'Memo Invoice' || invoiceid?.memoid) ? 'MO' : ''}{String(invoiceID).padStart(3, '0')}
          <IconButton onClick={handleClose} style={{ float: "right" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height: "90%" }}>
          {loading === true ? (
            <div class="d-spinner">
              <SpinnerRoundFilled
                size={51}
                thickness={125}
                speed={80}
                color="rgba(57, 107, 172, 1)"
                className="spp"
                enabled={loading === true}
              />
            </div>
          ) : (
            <>
              {pdfData ? (
                <iframe
                  title="PDF Viewer"
                  // src={pdfData}
                  src={`${pdfData}#toolbar=0`}
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              ) : (
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    variant="h4"
                    sx={{ display: "flex", justifyContent: "center" }}
                    className="error-o"
                  >
                    {Error}
                  </Typography>
                </div>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
