import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { formatDate } from "../../../../common/components/Helper/Utils";

const columns = [
  { id: "index", label: "Serial No", minWidth: 100 },
  { id: "createdon", label: "Date", minWidth: 100 },
  {
    id: "amount",
    label: "Payment Amount ($)",
    minWidth: 100,
  },
  {
    id: "paymentmethod",
    label: "Payment Method",
    minWidth: 100,
  },
  {
    id: "paymentmethod_details",
    label: "Payment Details",
    minWidth: 100,
  },
  {
    id: "adjust_notes",
    label: "Notes",
    minWidth: 100,
  },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function InvoiceModal(props) {
  const { open, handleClose, invoice, getPaymentHistory } = props;
  const [rows, setRow] = useState([]);

  useEffect(() => {
    if (invoice && invoice.id) {
      getPaymentHistory({ invoiceId: invoice?.id }, (error, res) => {
        getPaymentHistory({ invoiceId: invoice?.id }, (error, res) => {
          if (res?.data) {
            const response = res.data;
            const adjustedPaymentIndex = response.findIndex(item => item.paymentmethod === "Adjust");

            if (adjustedPaymentIndex !== -1) {
              const adjustedPayment = response[adjustedPaymentIndex];
              const filteredPayments = response.filter(item => item.paymentmethod !== "Adjust");
              filteredPayments.splice(adjustedPaymentIndex, 0, adjustedPayment);
              setRow(filteredPayments);
            } else {
              setRow(response);
            }
          }
        });

      });
    }
  }, [invoice, getPaymentHistory]);

  const handleAfterPrint = React.useCallback(() => {
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    return Promise.resolve();
  }, []);

  const handleBeforePrint = React.useCallback(() => {
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div id="printable-content">
          <div>
            <DialogTitle id="alert-dialog-title ">
              Payment History
              <IconButton
                onClick={() => {
                  handleClose();
                }}
                style={{ float: "right" }}
                className="noprint"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            <ReactToPrint
              onBeforeGetContent={handleOnBeforeGetContent}
              onAfterPrint={handleAfterPrint}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint
              trigger={() => (
                <Button
                  variant="contained"
                  color="primary"
                  className="text-capitalize primary-bg color-white noprint"
                  startIcon={<PrintIcon />}
                >
                  PRINT
                </Button>
              )}
              content={() => document.getElementById("printable-content")}
            />
          </div>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ marginTop: "-20px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div>
                  <div>
                    <Typography variant="subtitle2">
                      Name: {invoice?.contactname || "-"}{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2">
                      Invoice ID: {invoice?.id || "-"}{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2">
                      Payment status: {invoice?.paymentstatus || "-"}{" "}
                    </Typography>
                  </div>
                </div>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
               
              
              </div> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <Typography variant="subtitle2">
                      Invoice Amount: {invoice?.totalprice || "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2">
                      Balance: {invoice?.remainingbalance || "-"}
                    </Typography>
                  </div>
                </div>
              </div>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length ? (
                      <>
                        {rows.map((row, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={row.code}>
                              {columns.map((column) => {
                                const value = row[column.id];

                                return (
                                  <>
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                        typeof value === "number"
                                        ? column.format(value)
                                        : column.id === "createdon" && value
                                          ? // ? moment(value).format("MM/DD/YYYY")
                                          formatDate(value, "MM/DD/YYYY")
                                          : column.id === "index"
                                            ? index + 1
                                            : value || "-"}
                                    </TableCell>
                                  </>
                                );
                              })}
                            </TableRow>
                          );
                        })}{" "}
                      </>
                    ) : (
                      <TableCell colspan={12} style={{ textAlign: "center" }}>
                        <Typography variant="h6" className="color-BCBCBC">
                          No Transactions Found
                        </Typography>
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
