import React, { useEffect, useState } from 'react'
import Typography from "@mui/material/Typography";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { downloadInvoicepreview } from "../../dealer/actions/dealerAction";
import { SpinnerRoundFilled } from "spinners-react";
import { useLocation } from "react-router-dom";

function ViewInvoice(props) {
  const location = useLocation();
  let { invoiceFilter } = location.state || {};
  const { open, handleClose } = props;
  const [pdfData, setPdfData] = useState("");
  const [Error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const viewInvoice = () => {
    const { invoiceId, isMemo } = props?.location?.state;
    setLoading(true);
    downloadInvoicepreview(invoiceId, isMemo).then((res) => {
      setLoading(false);
      if (res && res.status === 0) {
        setError(
          "Sorry! Invoice preview not available. Please contact support"
        );
      } else if (res && res.url) {
        setPdfData(res.url);
        setError("");
      }
    });
  };

  const handleCancel = () => {
    if(props?.location?.state?.isMemo && invoiceFilter) {
      invoiceFilter.type = 'memo';
    } else if(invoiceFilter){
      invoiceFilter.type = 'invoice';
    } else if(props?.location?.state?.isMemo) {
      invoiceFilter = { type: 'memo' };
    } else {
      invoiceFilter = { type: 'invoice' };
    }
      props.history.push({
        pathname: "/invoices",
        state: { invoiceFilter },
      });
  }

  useEffect(() => {
    viewInvoice();
  }, []);

  return (

    <div
      open={open}
      onClose={handleClose}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
      // fullWidth={true}
      maxWidth={"lg"}
      PaperProps={{
        lg: { height: "96%" },
        sx: { height: "96%" },
        md: { height: "96%" },
      }}
    >
      <DialogTitle>
        Invoice Preview - {props?.location?.state?.isMemo ? 'MO' : ''}{String(props?.location?.state?.invoiceId).padStart(3, '0')}
        <IconButton onClick={handleCancel} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div style={{ height: 'auto' }}>
        {loading === true ? (
          <div class="d-spinner">
            <SpinnerRoundFilled
              size={51}
              thickness={125}
              speed={80}
              color="rgba(57, 107, 172, 1)"
              className="spp"
              enabled={loading === true}
            />
          </div>
        ) : (
          <>
            {pdfData ? (
              <iframe
                title="PDF Viewer"
                src={`${pdfData}#toolbar=0`}
                // src={pdfData}
                style={{
                  position: "relative",
                  width: "85rem",
                  height: "75rem",
                }}
              ></iframe>
            ) : (
              <div style={{ marginTop: "20px" }}>
                <Typography
                  variant="h4"
                  sx={{ display: "flex", justifyContent: "center" }}
                  className="error-o"
                >
                  {Error}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}


export default ViewInvoice