import React, { Component } from "react";
import Header from "../../common/components/Header";
import FooterComponent from "../../common/components/Footer";
import { withRouter } from "react-router";
import UpgradePlanComponent from "../../components/pages/subscription/upgradePlanComponent";
const { innerHeight: height } = window;
class ContentWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.location.pathname.includes("/administration")) {
            localStorage.setItem("menuTitle", "User Management");
        } else {
            localStorage.setItem("", "");
        }

        return (
            <div style={{ backgroundColor: "white" }}>
                <Header />
                <div
                    style={{ backgroundColor: "white", minHeight: height - 145 + "px" }}
                >
                    {this.props.contentView}
                    {this.props.showSubscriptionPopup && (
                        <UpgradePlanComponent isOpen={this.props.showSubscriptionPopup} />
                    )}
                </div>
                <FooterComponent />
            </div>
        );
    }
}
export default withRouter(ContentWrapper);
