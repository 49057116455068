import moment from "moment";
import { toast } from "react-toastify";

export function formatPhoneNumber(phoneNumberString, country, countryCode) {

  if (phoneNumberString && country && (country === "United Kingdom" || country === "United States" || country === "Canada")) {
    let phoneReg = /^(1|)?(\d{3})(\d{3})(\d{4})$/;
    if (country === "United Kingdom") {
      phoneReg = /^(44|)?(\d{4})(\d{6})$/;
    }
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(phoneReg);
    if (match) {
      const intlCode = (country === "United Kingdom" ? '+44 ' : '+1 ');

      let phFormat = [intlCode, '(', match[2], ') ', match[3], '-', match[4]];
      if (country === "United Kingdom") {
        phFormat = [intlCode, match[2], ' ', match[3]];
      } else if (country === "Canada") {
        phFormat = [intlCode, match[2], ' ', match[3], ' ', match[4]];
      }
      return phFormat.join('');
    }
  } else if (phoneNumberString && countryCode) {
    return `+${countryCode} ${phoneNumberString}`
  } else if (phoneNumberString) {
    return phoneNumberString
  } else {
    return null;
  }


}

export function unformatPhoneNumber(phoneNumberString, country, phoneCode) {
  console.log('unformatPhoneNumber', phoneNumberString, country, phoneCode)

  if (phoneCode) {
    let formatedPhoneNumber = phoneNumberString.replace('+1', '');
    return formatedPhoneNumber.replace(`+${phoneCode}`, '')
  } else {
    return phoneNumberString.replace('+1', '')
  }
}


export function getComponentActionList(component = "") {
  const userObject = window.sessionStorage.getItem('userDetails');
  const userData = JSON.parse(userObject);
  const rolesObject = userData.roleAccess;
  console.log(rolesObject)
  let actionsArray = [];
  let indexObject = rolesObject?.pages.filter((item) => { return item.component === component });
  let allowedActions = indexObject && indexObject.length > 0 ? indexObject[0].actions : [];
  allowedActions.forEach(element => {
    if (element.role_is_active) {
      actionsArray.push(element.action);
    }
  });
  return actionsArray;
};

export function getComponentViewsList(component = "") {
  const userObject = window.sessionStorage.getItem('userDetails');
  const userData = JSON.parse(userObject);
  const rolesObject = userData.roleAccess;
  let actionsArray = [];
  let allowedActions = [];
  let indexObject = rolesObject?.pages.filter((item) => { return item.component === component });
  allowedActions = indexObject && indexObject.length > 0 ? indexObject[0]?.views ? indexObject[0]?.views : [] : [];
  allowedActions.forEach(element => {
    if (element.role_is_active) {
      actionsArray.push(element.action);
    }
  });
  return actionsArray;
};

export function isActionAllowed(actionsArray, actionUrl = "") {
  return actionsArray.indexOf(actionUrl) >= 0 ? true : false;
};

export const showErrorToast = (error) => {
  const errorMsg = error?.response?.data?.message ?? error;
  return toast(errorMsg, {
    autoClose: 5000,
    type: toast.TYPE.ERROR,
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const showSuccessToast = (successMsg) => {
  toast(successMsg, {
    autoClose: 5000,
    type: toast.TYPE.SUCCESS,
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const removeSpecialChar = (value) => {
  value = value.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
  value = value.replaceAll("-", "");
  return value.trim();
}

export const formatDate = (value, format = "MM/DD/YYYY", isUtc = true) => {
  return isUtc
    ? moment.utc(value).format(format)
    : moment(value).format(format);
};

export const replaceNullValues = function(obj) {
  if (Array.isArray(obj)) {
    return obj.map(replaceNullValues); // Recursively handle arrays
  } else if (obj !== null && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, replaceNullValues(value)])
    );
  }
  return obj === null ? "" : obj; // Replace null with an empty string
}

export const isTwoAddressSame = (inputObj, dbDataObj, type, billingCountryCode, shippingCountryCode) => {
  let isChanged = false;
  const input = replaceNullValues(inputObj);
  const dbData = replaceNullValues(dbDataObj);
  if (type === "shipping") {
    if (
      (dbData.businessname && dbData.businessname.toLowerCase()) !==
      (input.shippingToBusinessName &&
        input.shippingToBusinessName.toLowerCase()) ||
      (dbData.address1 && dbData.address1.toLowerCase()) !==
      (input.shippingToAddressLine1 &&
        input.shippingToAddressLine1.toLowerCase()) ||
      (dbData.address2 && dbData.address2.toLowerCase()) !==
      (input.shippingToAddressLine2
        ? input.shippingToAddressLine2 &&
        input.shippingToAddressLine2.toLowerCase()
        : "") ||
      (dbData.city && dbData.city.toLowerCase()) !==
      (input.shippingToCity && input.shippingToCity.toLowerCase()) ||
      (dbData.states && dbData.states.toLowerCase()) !==
      (input.shippingToState && input.shippingToState.toLowerCase()) ||
      (dbData.country && dbData.country === "" && input.shippingToCountry.toLowerCase() !== 'united states') ||
      (dbData.country && dbData.country !== "" && (dbData.country.toLowerCase() !== input.shippingToCountry.toLowerCase())) ||
      (dbData.country && dbData.country.toLowerCase()) !==
      (input.shippingToCountry && input.shippingToCountry.toLowerCase()) ||
      (dbData.zipcode && dbData.zipcode.toLowerCase()) !==
      (input.shippingToZip && input.shippingToZip.toLowerCase()) ||
      (dbData.countrycode === "" && shippingCountryCode !== '+1') ||
      (dbData.countrycode !== "" && (dbData.countrycode !== shippingCountryCode))
    ) {
      isChanged = true;
    }
    return isChanged;
  } else {
    if (
      (dbData.businessname && dbData.businessname.toLowerCase()) !==
      (input.billingToBusinessName &&
        input.billingToBusinessName.toLowerCase()) ||
      (dbData.address1 && dbData.address1.toLowerCase()) !==
      (input.billingToAddressLine1 &&
        input.billingToAddressLine1.toLowerCase()) ||
      (dbData.address2 && dbData.address2.toLowerCase()) !==
      (input.billingToAddressLine2 &&
        input.billingToAddressLine2.toLowerCase()) ||
      (dbData.city && dbData.city.toLowerCase()) !==
      (input.billingToCity && input.billingToCity.toLowerCase()) ||
      (dbData.states && dbData.states.toLowerCase()) !==
      (input.billingToState && input.billingToState.toLowerCase()) ||
      (dbData.country && dbData.country === "" && input.billingToCountry.toLowerCase() !== 'united states') ||
      (dbData.country && dbData.country !== "" && (dbData.country.toLowerCase() !== input.billingToCountry.toLowerCase()))||
      (dbData.zipcode && dbData.zipcode.toLowerCase()) !==
      (input.billingToZip && input.billingToZip.toLowerCase()) ||
      (dbData.countrycode === "" && billingCountryCode !== '+1') ||
      (dbData.countrycode !== "" && (dbData.countrycode !== billingCountryCode))
    ) {
      isChanged = true;
    }
    return isChanged;
  }
};