import React from "react";
import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    FormHelperText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from '@mui/icons-material/Info';

const ReasonDialog = ({
    title = "",
    message = "",
    label = "",
    info = "",
    open,
    handleChange,
    handleSubmit,
    handleClose,
    disabled = false,
    error = false,
    errorMsg = "Please provide a reason"
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle>
                {title}
                <IconButton
                    onClick={() => {
                        handleClose();
                    }}
                    style={{ float: "right" }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div> {message} </div>
                <div>
                    <FormControl style={{ width: "400px", margin: "20px 0px" }}>
                        <TextField
                            variant="outlined"
                            id="outlined-multiline-flexible"
                            multiline
                            maxRows={4}
                            required={true}
                            style={{ width: "400px !important" }}
                            label={label}
                            onChange={(e) => { handleChange(e) }}
                        />
                        {error && <FormHelperText className="error-o">{errorMsg}</FormHelperText>}
                    </FormControl>
                </div>
                <p style={{ display: "flex", alignItems: "center" }}>
                    <InfoIcon
                        style={{
                            marginRight: "5px",
                            height: "20px",
                            color: "rgba(0, 0, 0, 0.6)",
                        }}
                    />
                    {info}
                </p>
            </DialogContent>
            <DialogActions>
                <Button color="primary" autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button color="primary" disabled={disabled} onClick={handleSubmit}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReasonDialog;
